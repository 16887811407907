.app {
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  margin: 0;
  padding: 0;
  background: rgb(241, 82, 82);
  background: linear-gradient(
    290deg,
    rgba(241, 82, 82, 1) 0%,
    rgba(76, 76, 252, 1) 100%
  );
}
