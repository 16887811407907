.people {
  background-color: white;
  width: 90%;
  margin: 1rem auto;
  border: 3px solid #d1d1d1;
  border-radius: 15px;
  text-align: center;
  box-shadow: 3px 3px 10px 3px #232323;
  font-family: 'Roboto', sans-serif;
}

.people p {
  width: 80%;
  margin: 1rem auto;
}

.table {
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;
  width: 95%;
}

.table tr {
  border-bottom: 2px solid #454545;
}

.head th {
  padding-bottom: 1rem;
}

.table tbody td {
  padding: 0.5rem;
}

@media screen and (min-width: 760px) {
  .table tbody td:nth-child(6) {
    width: 20%;
  }
  .table tbody td:nth-child(2) {
    width: 20%;
  }
  .table tbody td:nth-child(1) {
    width: 20%;
  }
}

@media screen and (max-width: 760px) {
  .head {
    display: none;
  }
  .table {
    border-collapse: collapse;
  }
  .table tbody td {
    display: block;
    margin: -0.5rem;
    padding: 0;
  }
  .table tbody tr {
    border-bottom: 2px solid #454545;
  }
}
