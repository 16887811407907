.home {
  background-color: white;
  width: 60%;
  margin: 0 auto;
  border: 3px solid #d1d1d1;
  border-radius: 15px;
  padding: 1rem 1rem 3rem 1rem;
  box-shadow: 3px 3px 10px 3px #232323;
  font-family: 'Roboto', sans-serif;
}

.content {
  width: 80%;
  margin: auto;
  text-align: center;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .home {
    width: 75%;
  }
}
