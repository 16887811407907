.container {
  max-width: fit-content;
  text-align: center;
  border-radius: 15px;
  padding: 0.25rem;
  background-color: #f0f0f0;
  margin: 1rem auto;
  box-shadow: 2px 2px 6px 2px #232323;
  border: 2px solid #d1d1d1;
}

.active button {
  background-image: linear-gradient(
    145deg,
    rgba(241, 82, 82, 1) 0%,
    rgba(76, 76, 252, 1) 100%
  );
  color: white;
  font-weight: bold;
}
